import { TAccentColor } from '../models/accent-color.model';

export function getColorInSequence(index: number, colors = defaultColors) {
    if (index > colors.length - 1) {
        const times = Math.floor(index / colors.length);
        const colorIndex = index - colors.length * times;
        return colors[colorIndex];
    }
    return colors[index];
}

const defaultColors: Array<TAccentColor> = ['blue', 'pink', 'yellow', 'purple'];
