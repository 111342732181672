import React from 'react';
import { usePageContext, IPage } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import { graphql, useStaticQuery } from 'gatsby';

import { section, sectionNav } from './section-ecommerce-navigation.module.scss';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IOfferCategory } from '../../models/offer.model';
import { useT } from '../../hooks/use-translation';
import { getNodes } from '../../utils/get-nodes';

import Section from '../hoc/section';
import NavigationPills from '../molecules/navigation-pills';

interface ISectionEcommerceNavigationProps {
    className?: string;
    isNavToId?: boolean;
    showGoBackLink?: boolean;
}

interface ISectionEcommerceNavigationQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname' | 'type'>>;
    allOfferCategory: IQueryAllResult<Pick<IOfferCategory, 'pathname' | 'locale'>>;
}

const SectionEcommerceNavigation: React.FC<ISectionEcommerceNavigationProps> = ({
    className = '',
    isNavToId = false,
    showGoBackLink = true,
}) => {
    const { allPage, allOfferCategory } =
        useStaticQuery<ISectionEcommerceNavigationQueryResult>(query);
    const { locale } = usePageContext();
    const { t } = useT();

    const pages = getNodes(allPage);
    const faqAlterpressPathname = pages.find(
        (page) => page.locale === locale && page.type === 'faq-alterpress'
    )?.pathname;
    const apaczkaAlterpressPathname = pages.find(
        (page) => page.locale === locale && page.type === 'apaczka-alterpress'
    )?.pathname;
    const functionalitiesAlterpressPathname = pages.find(
        (page) => page.locale === locale && page.type === 'functionalities-alterpress'
    )?.pathname;
    const configuratorAlterpressPathname = pages.find(
        (page) => page.locale === locale && page.type === 'configurator-alterpress'
    )?.pathname;
    const casesAlterpressPathname = pages.find(
        (page) => page.locale === locale && page.type === 'cases-alterpress'
    )?.pathname;
    const eCommercePathname =
        getNodes(allOfferCategory).find((category) => category.locale === locale)?.pathname || '';

    const backNavigationItem = {
        to: eCommercePathname,
        label: t('ecommerce.nav.back'),
        isBackLink: true,
    };

    const ecommerceNavigationItems = [
        {
            to: isNavToId
                ? '#functionalities'
                : functionalitiesAlterpressPathname || `${eCommercePathname}#functionalities`,
            label: t('ecommerce.nav.functionalities'),
        },
        {
            to: isNavToId
                ? '#apaczka'
                : apaczkaAlterpressPathname || `${eCommercePathname}#apaczka`,
            label: t('ecommerce.nav.apaczka'),
        },
        {
            to: isNavToId
                ? '#configurator'
                : configuratorAlterpressPathname || `${eCommercePathname}#configurator`,
            label: t('ecommerce.nav.configurator'),
        },
        {
            to: isNavToId ? '#faq' : faqAlterpressPathname || `${eCommercePathname}#faq`,
            label: t('ecommerce.nav.faq'),
        },
        {
            to: isNavToId ? '#cases' : casesAlterpressPathname || `${eCommercePathname}#cases`,
            label: t('ecommerce.nav.cases'),
        },
    ];

    return (
        <Section
            className={`${className} ${section} ${sectionNav}`}
            theme="light"
            circleDisplay="none"
            contentDisplay="center"
            height="auto"
        >
            <NavigationPills
                navPills={
                    showGoBackLink
                        ? [backNavigationItem, ...ecommerceNavigationItems]
                        : ecommerceNavigationItems
                }
            />
        </Section>
    );
};

const query = graphql`
    query {
        allPage(
            filter: {
                type: {
                    in: [
                        "faq-alterpress"
                        "apaczka-alterpress"
                        "functionalities-alterpress"
                        "configurator-alterpress"
                        "cases-alterpress"
                    ]
                }
            }
        ) {
            edges {
                node {
                    type
                    locale
                    pathname
                }
            }
        }
        allOfferCategory(filter: { name: { eq: "eCommerce" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
    }
`;

export default SectionEcommerceNavigation;
