import React from 'react';
import { graphql } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';

import {
    layout,
    hero,
    introTitle,
    introText,
    introAccent,
    subtitle,
    integrationList,
    integrationItem,
    integrationItemHeader,
    integrationItemTitle,
    integrationItemText,
    installationLead,
    installationList,
    installationItem,
    installationItemTitle,
    installationItemNumber,
    installationItemText,
    one,
    two,
    three,
    line,
    sectionInstallation,
    contentInstallation,
    workLead,
    workStepTitle,
    workStepText,
    workContentBox,
    sectionWork,
} from './apaczka.module.scss';
import CostModelIcon from '../../src/assets/images/svg/dollar-hand.svg';
import DocsIcon from '../../src/assets/images/svg/docs.svg';
import NoMinimumIcon from '../../src/assets/images/svg/disk-hand.svg';
import GlobeIcon from '../../src/assets/images/svg/globe.svg';
import { IStepsTimelinePoint } from '../models/steps-timeline-point.model';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import StepsTimeline from '../components/molecules/steps-timeline';
import SectionEcommerceNavigation from '../components/organisms/section-ecommerce-navigation';

interface IApaczkaPageProps {
    readonly data: {
        introImage: ImageDataLike;
        installationImage: ImageDataLike;
        workImage: ImageDataLike;
    };
}

const ApaczkaPage: React.FC<IApaczkaPageProps> = ({ data }) => {
    const { t } = useT();
    const { introImage, installationImage, workImage } = data;

    const integrationItems = [
        {
            Icon: CostModelIcon,
            title: t('apaczka.integration.item.title.1'),
            text: t('apaczka.integration.item.text.1'),
        },
        {
            Icon: DocsIcon,
            title: t('apaczka.integration.item.title.2'),
            text: t('apaczka.integration.item.text.2'),
        },
        {
            Icon: NoMinimumIcon,
            title: t('apaczka.integration.item.title.3'),
            text: t('apaczka.integration.item.text.3'),
        },
        {
            Icon: GlobeIcon,
            title: t('apaczka.integration.item.title.4'),
            text: t('apaczka.integration.item.text.4'),
        },
    ];

    const installationSteps = [
        {
            title: t('apaczka.installation.step.title.1'),
            text: t('apaczka.installation.step.text.1'),
            className: one,
        },
        {
            title: t('apaczka.installation.step.title.2'),
            text: t('apaczka.installation.step.text.2'),
            className: two,
        },
        {
            title: t('apaczka.installation.step.title.3'),
            text: t('apaczka.installation.step.text.3'),
            className: three,
        },
    ];

    const workSteps: IStepsTimelinePoint[] = [
        {
            stepId: 1,
            title: t('apaczka.work.step.title.1'),
            content: t('apaczka.work.step.text.1'),
            locale: '',
            sequence: 1,
        },
        {
            stepId: 2,
            title: t('apaczka.work.step.title.2'),
            content: t('apaczka.work.step.text.2'),
            locale: '',
            sequence: 2,
        },
        {
            stepId: 3,
            title: t('apaczka.work.step.title.3'),
            content: t('apaczka.work.step.text.3'),
            locale: '',
            sequence: 3,
        },
        {
            stepId: 4,
            title: t('apaczka.work.step.title.4'),
            content: t('apaczka.work.step.text.4'),
            locale: '',
            sequence: 4,
        },
        {
            stepId: 5,
            title: t('apaczka.work.step.title.5'),
            content: t('apaczka.work.step.text.5'),
            locale: '',
            sequence: 5,
        },
        {
            stepId: 6,
            title: t('apaczka.work.step.title.6'),
            content: t('apaczka.work.step.text.6'),
            locale: '',
            sequence: 6,
        },
    ];

    return (
        <MainLayout className={layout} showFloatingCircles={false}>
            <Hero
                className={hero}
                titleProps={{ children: t('apaczka.hero.title'), Tag: 'h1' }}
                color="purple"
            />
            <SectionEcommerceNavigation />
            <Section
                theme="light"
                circleDisplay="none"
                contentDisplay="right"
                height="auto"
                titleProps={{
                    className: introTitle,
                    children: (
                        <>
                            {t('apaczka.intro.title.1')}{' '}
                            <span className={introAccent}>{t('apaczka.intro.title.2')}</span>
                        </>
                    ),
                    weight: 'bold',
                    size: 'large',
                }}
                textProps={{ children: t('apaczka.intro.text'), className: introText }}
                image={getImage(introImage)}
            />
            <Section
                theme="light"
                circleDisplay="none"
                contentDisplay="center"
                height="auto"
                titleProps={{
                    children: t('apaczka.integration.title'),
                    subtitle: t('apaczka.integration.subtitle'),
                    subtitleClass: subtitle,
                    subtitleColor: 'purple',
                    weight: 'bold',
                    size: 'large',
                }}
            >
                <ul className={integrationList}>
                    {integrationItems.map((item, index) => {
                        const Icon = item.Icon;
                        return (
                            <li key={`integration-item-${index}`} className={integrationItem}>
                                <div className={integrationItemHeader}>
                                    <Icon />
                                    <h3 className={integrationItemTitle}>{item.title}</h3>
                                </div>
                                <p className={integrationItemText}>{item.text}</p>
                            </li>
                        );
                    })}
                </ul>
            </Section>
            <Section
                theme="dark"
                circleDisplay="none"
                contentDisplay="left"
                className={sectionInstallation}
                contentClassName={contentInstallation}
                titleProps={{
                    children: t('apaczka.installation.title'),
                    subtitle: t('apaczka.installation.subtitle'),
                    subtitleClass: subtitle,
                    subtitleColor: 'blue',
                    size: 'large',
                    weight: 'bold',
                }}
                textProps={{
                    children: t('apaczka.installation.lead'),
                    className: installationLead,
                }}
                image={getImage(installationImage)}
                height="auto"
            >
                <ul className={installationList}>
                    {installationSteps.map((step, index) => {
                        let stepNumber = (index + 1).toString();
                        stepNumber = stepNumber.length > 1 ? stepNumber : `0${stepNumber}`;
                        return (
                            <li
                                className={`${installationItem} ${step.className}`}
                                key={`installation-step-${index}`}
                            >
                                <span className={line} />
                                <h3 className={installationItemTitle}>
                                    <span className={installationItemNumber}>{stepNumber}.</span>{' '}
                                    <span>{step.title}</span>
                                </h3>
                                <p className={installationItemText}>{step.text}</p>
                            </li>
                        );
                    })}
                </ul>
            </Section>
            <Section
                theme="light"
                circleDisplay="none"
                contentDisplay="center"
                height="auto"
                className={sectionWork}
                titleProps={{
                    children: t('apaczka.work.title'),
                    subtitle: t('apaczka.work.subtitle'),
                    subtitleClass: subtitle,
                    size: 'large',
                    weight: 'bold',
                    subtitleColor: 'pink',
                }}
                textProps={{ children: t('apaczka.work.lead'), className: workLead }}
            />
            <Section
                theme="light"
                circleDisplay="none"
                contentDisplay="right"
                height="auto"
                image={getImage(workImage)}
                contentBoxClassName={workContentBox}
            >
                <StepsTimeline
                    points={workSteps}
                    TitleTag="h3"
                    stepsTimelinePointProps={{
                        useMarkdown: true,
                        showStepNumber: false,
                        circleOrientation: 'left',
                        titleClassName: workStepTitle,
                        textClassName: workStepText,
                        borderOpacity: 0.25,
                    }}
                    colorsSequence={['yellow', 'pink', 'blue', 'purple']}
                />
            </Section>
            <SectionEcommerceNavigation />
        </MainLayout>
    );
};

export const query = graphql`
    query {
        introImage: file(relativePath: { eq: "apaczka-intro.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
        }
        installationImage: file(relativePath: { eq: "apaczka-installation.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
        }
        workImage: file(relativePath: { eq: "apaczka-work.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 90)
            }
        }
    }
`;

export default ApaczkaPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
