import React from 'react';
import { Link } from 'gatsby';

import { link, active, back } from './nav-link-pill.module.scss';
import GoBackIcon from '../../assets/images/svg/arrow-back-up.svg';

interface INavLinkPillProps {
    className?: string;
    to: string;
    children?: React.ReactNode;
    isBackLink?: boolean;
}

const NavLinkPill: React.FC<INavLinkPillProps> = ({
    className = '',
    to,
    children,
    isBackLink = false,
}) => {
    return (
        <Link
            to={to}
            className={`${link} ${className} ${isBackLink ? back : ''}`}
            activeClassName={active}
        >
            {isBackLink && <GoBackIcon />} {children}
        </Link>
    );
};

export default NavLinkPill;
