import React from 'react';

import { IStepsTimelinePoint } from '../../models/steps-timeline-point.model';
import { TAccentColor } from '../../models/accent-color.model';
import { getColorInSequence } from '../../utils/get-color-in-sequence';

import StepsTimelinePoint, { IStepsTimelinePointProps } from '../atoms/steps-timeline-point';

interface IStepsTimelineProps {
    className?: string;
    TitleTag?: React.ElementType;
    points: IStepsTimelinePoint[];
    stepsTimelinePointProps?: Omit<IStepsTimelinePointProps, 'point' | 'number'>;
    colorsSequence?: Array<TAccentColor>;
}

const StepsTimeline: React.FC<IStepsTimelineProps> = ({
    className = '',
    points,
    TitleTag = 'h2',
    stepsTimelinePointProps,
    colorsSequence,
}) => {
    return (
        <ul className={className}>
            {points.map((point, index) => {
                return (
                    <li key={point.stepId}>
                        <StepsTimelinePoint
                            TitleTag={TitleTag}
                            circleOrientation={index % 2 === 0 ? 'left' : 'right'}
                            point={point}
                            number={index + 1}
                            color={getColorInSequence(index, colorsSequence)}
                            {...stepsTimelinePointProps}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

export default StepsTimeline;
