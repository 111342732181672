// extracted by mini-css-extract-plugin
export var contentInstallation = "apaczka-module--content-installation--MdbiD";
export var hero = "apaczka-module--hero--6Z+pS";
export var installationItem = "apaczka-module--installation-item--mOuUE";
export var installationItemNumber = "apaczka-module--installation-item-number--qVsKE";
export var installationItemText = "apaczka-module--installation-item-text--2Zwg1";
export var installationItemTitle = "apaczka-module--installation-item-title--YdoQa";
export var installationLead = "apaczka-module--installation-lead--XYNpF";
export var installationList = "apaczka-module--installation-list--jFZ9j";
export var integrationItem = "apaczka-module--integration-item--mROwy";
export var integrationItemHeader = "apaczka-module--integration-item-header--kfnCA";
export var integrationItemText = "apaczka-module--integration-item-text--Vszcf";
export var integrationItemTitle = "apaczka-module--integration-item-title--Pm-1L";
export var integrationList = "apaczka-module--integration-list--2C92u";
export var introAccent = "apaczka-module--intro-accent--8vYGT";
export var introText = "apaczka-module--intro-text--xyu85";
export var introTitle = "apaczka-module--intro-title--JbnbT";
export var layout = "apaczka-module--layout--51hNV";
export var line = "apaczka-module--line--me03w";
export var one = "apaczka-module--one--9ncYf";
export var sectionInstallation = "apaczka-module--section-installation--ceNAz";
export var sectionWork = "apaczka-module--section-work--GekTM";
export var subtitle = "apaczka-module--subtitle--Ds+RZ";
export var three = "apaczka-module--three--tEhlx";
export var two = "apaczka-module--two--o2AIf";
export var workContentBox = "apaczka-module--work-content-box--sHxDn";
export var workLead = "apaczka-module--work-lead--MHKQA";
export var workStepText = "apaczka-module--work-step-text--+DwZU";
export var workStepTitle = "apaczka-module--work-step-title--japSb";