import React from 'react';

import { list } from './navigation-pills.module.scss';

import NavLinkPill from '../atoms/nav-link-pill';

interface INavigationPillsProps {
    className?: string;
    navPills: { label: string; to: string; isBackLink?: boolean }[];
}

const NavigationPills: React.FC<INavigationPillsProps> = ({ className = '', navPills }) => {
    return (
        <nav className={className}>
            <ul className={list}>
                {navPills.map((pill, index) => {
                    return (
                        <li key={`pill-${index}`}>
                            <NavLinkPill to={pill.to} isBackLink={pill.isBackLink}>
                                {pill.label}
                            </NavLinkPill>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default NavigationPills;
